

export namespace Bypass {
  export let init = () => {
    let bypass = document.querySelector(".bypass")
    document.querySelectorAll(".bypass a").forEach(link => {
      link.addEventListener('focus', () => {
        if(bypass) {
          bypass.classList.remove('sr-only');
        }
      });

      link.addEventListener('blur', () => {
        if(bypass) {
          bypass.classList.add('sr-only');
        }
      });
    })
  };
}

Bypass.init()
