export function checkForm(form) {
    if (form.requester.name == '') {
        document.getElementById("name-feedback")!.style.display = "block";
        document.getElementById("name")!.setAttribute("aria-describedby", "name-feedback");
        document.getElementById("name")!.setAttribute("aria-invalid", "true");
    } else {
        document.getElementById("name-feedback")!.style.display = "none";
        document.getElementById("name")!.removeAttribute("aria-describedby");
        document.getElementById("name")!.removeAttribute("aria-invalid");
    }
    if (form.requester.email == '') {
        document.getElementById("email-feedback")!.style.display = "block";
        document.getElementById("email")!.setAttribute("aria-describedby", "email-feedback");
        document.getElementById("email")!.setAttribute("aria-invalid", "true");
    } else {
        document.getElementById("email-feedback")!.style.display = "none";
        document.getElementById("email")!.removeAttribute("aria-describedby");
        document.getElementById("email")!.removeAttribute("aria-invalid");
    }
    if (form.text == '') {
        document.getElementById("text-feedback")!.style.display = "block";
        document.getElementById("text")!.setAttribute("aria-describedby", "text-feedback");
        document.getElementById("text")!.setAttribute("aria-invalid", "true");
    } else {
        document.getElementById("text-feedback")!.style.display = "none";
        document.getElementById("text")!.removeAttribute("aria-describedby");
        document.getElementById("text")!.removeAttribute("aria-invalid");
    }

    return !(form.requester.name == '' || form.requester.email == '' || form.text == '');
}
