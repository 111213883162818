import './bootstrap-init';
import './modules/grecaptcha'
import './modules/Lazyload'
import './modules/Faq';
import './modules/Accordion';
import './modules/Home';
import './modules/Bypass';
import './modules/Footer';
import './modules/FormValidation';
import './modules/Model/FormChaines';
import './modules/Model/Requester';